<template>
     <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="caz-blocks-sarcho-title">
                        <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                            {{$t('message.managers')}}
                        </div>
                        <div class="block-sarche">
                            <div class="header__search">
                                <el-input
                                    :placeholder="$t('message.search')"
                                    prefix-icon="el-icon-search"
                                    :class="mode ? 'input__day' : 'input__night'"
                                    v-model="searchBlock"
                                    size="small"
                                    round
                                    style="width: 100% !important"
                                ></el-input>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :span="12" class="flex-style text-right">
                    <div style="display:flex; float: right;" class="text-right">
                        <span class="align-items-center align-self-center pr-2">
                            <span :class="mode ? 'price__day' : 'price__night'" class="el-tag el-tag--light blue__night custom_size_15">
                                Кол: {{ (exhibition.participants.length > 0) ? exhibition.participants.length : 0 }}
                            </span>
                        </span>
                        <!-- <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                        >
                        </crm-create-and-column-settings> -->
                        <div :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                            <el-button size="small"  class="btu-color" @click="drawerCreate = true" >
                                {{ $t("message.add_managers") }}
                            </el-button>
                        </div>
                        
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart">
            <table
                class="table-my-code table-bordered"
                v-loading="loadingData"
                :class="mode ? 'table__myday' : 'table__mynight'"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.avatar.show">
                            {{ columns.avatar.title }}
                        </th>

                        <th v-if="columns.name.show">
                            {{ columns.name.title }}
                        </th>

                        <th v-if="columns.phone.show">
                            {{ columns.phone.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.avatar.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                disabled
                                size="mini"
                                v-model="filterForm.avatar"
                                :placeholder="columns.avatar.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.name.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.name"
                                :placeholder="columns.name.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.phone.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.phone"
                                :placeholder="columns.phone.title"
                            ></el-input>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr v-for="(user, index) in exhibition.participants" :key="'user-'+index" class="cursor-pointer">
                        <td v-if="columns.id.show">{{ user.id }}</td>

                        <td v-if="columns.avatar.show">
                            <div
                                class="gc-small-block rounded-circle hidden mr-3"
                                style="border: 1px solid gray"
                            >
                                <img
                                :src="user ? (user.avatar ? user.avatar : '/img/default-user-icon.png') : '/img/default-user-icon.png'"
                                alt="Default Avatar"
                                class="d-block"
                                />
                            </div>
                        </td>
                        <td v-if="columns.name.show">
                            {{ user.name }}
                        </td>

                        <td v-if="columns.phone.show">
                            {{ user.phone }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-dropdown @command="participantSettings">
                                <span class="el-dropdown-link more_icons">
                                <i class="el-icon-arrow-down el-icon-more-outline"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" size="mini">      
                                    <el-dropdown-item :command="{action: 'delete', user: user}" icon="el-icon-edit el-icon--left">       
                                        {{ $t('message.delete') }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>


        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerCreate"
                ref="drawerCreate"
                size="50%"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <div>
                   <CrmCreate ref="drawerUpdateChild" @addToParticipantsList="addToParticipantsList" drawer="drawerCreate"></CrmCreate>
                </div>
            </el-drawer>

            
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CrmCreate from "./components/crm-create";
export default {
    name: "exhibitionEmployees",
    components: {
      CrmCreate,
    },

    data() {
        return {
            loadingData: false,
            drawerCreate: false,
            checked: false,
            filterForm: {
                id: "",
                name: "",
                phone:"",
            },
            searchBlock: '',
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },
                avatar: {
                    show: true,
                    title: "Avatar",
                    sortable: true,
                    column: "avatar",
                },
                name: {
                    show: true,
                    title: "Name",
                    sortable: true,
                    column: "name",
                },
                phone: {
                    show: true,
                    title: "Телефон",
                    sortable: true,
                    column: "phone",
                },
                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            exhibition: "exhibitions/model",
        }),
    },
    methods: {
        ...mapActions({
            updateParticipants: "exhibitions/updateParticipants",
            updateColumn: "users/updateColumn",
        }),
        addToParticipantsList(participants){
            this.$store.commit('exhibitions/ADD_PARTICIPANTS', participants);
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed()
                }
            }
        },
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        participantSettings(command){
            if(command.action === 'delete'){
                this.$confirm(
                    this.$t('message.do_you_really_want_to_do_this'),
                    this.$t('message.warning'), 
                    {
                        confirmButtonText: this.$t('message.yes'),
                        cancelButtonText: this.$t('message.no'),
                        type: "warning"
                    }
                )
                .then(() => {
                    let data = {
                        action: 'detach',
                        exhibition_id: this.exhibition.id,
                        participants: [command.user.id]
                    }
                    this.updateParticipants(data)
                        .then(res => {
                            this.$store.commit('exhibitions/REMOVE_PARTICIPANT', command.user.id);
                            this.$alert(res);
                        }).catch(err => {

                        })
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.operation_canceled')
                    });
                });
                
            }
        }
    }
};
</script>

