<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">
                        {{ $t("message.add_managers") }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item
                                :label="$t('message.users')"
                                prop="users"
                                >
                                    <el-select v-model="form.participants" multiple placeholder="Select" class="w-100" size="medium">
                                        <el-option
                                            v-for="item in userList"
                                            :key="'user-'+item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <!-- end-col -->
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import selectUser from "@/components/inventory/select-users";
import { mapGetters, mapActions } from "vuex";

export default {
    props: ['drawer'],
    components: {
      selectUser,
    },
    data() {
        return {
            form: {
                exhibition_id: null,
                action: 'attach',
                participants: []
            },
            loadingButton: false,
            userList: [],
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            users: "users/inventory",
            authUser: "auth/user",
            exhibition: "exhibitions/model",
        }),
    },
    methods: {
        ...mapActions({
            updateUsersInventory: "users/inventory",
            updateParticipants: "exhibitions/updateParticipants",
        }),
        opened(){
            if (this.users && this.users.length === 0) {
                this.updateUsersInventory()
                    .then(res => {
                        this.setUsers();
                    }).catch(err => {

                    });
            } else if (this.users && this.users.length > 0){
                this.setUsers();
            }
            
        },
        setUsers(){
            this.form.exhibition_id = this.exhibition.id;
            this.userList = JSON.parse(JSON.stringify(this.users));
            this.userList = this.userList.filter(el => 
                (el.id !== this.authUser.id && (this.exhibition.participants.filter(item => item.id === el.id).length === 0))
            );
        },
        closed(){
            this.userList = [];
            this.form.participants = [];
            this.form.exhibition_id = null;
        },
        
        submit(){
            if(this.form.participants.length > 0){
                let data = this.userList.filter(item => this.form.participants.includes(item.id))
                this.updateParticipants(this.form)
                    .then(res => {
                        this.$emit('addToParticipantsList', data);
                        this.close();
                        this.$alert(res);
                    }).catch(err => {

                    })
            }else{
                this.close();
            }
        },
        parent() {
            return this.$parent.$parent;
        },
        close(){
          this.parent().closeDrawer(this.drawer);        
        }
    },
};
</script>
